// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import './stylesheet';

// Import all image files in a folder:
require.context('../images', true)

// Import OneUI JavaScript
import '../js/main/app';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  $('#locale-tab a').click(function (e) {
   e.preventDefault();
   $(this).tab('show');
 });
});


document.addEventListener("turbolinks:load", function() {
  window.One.helpersOnLoad(['one-table-tools-checkable', 'one-table-tools-sections']);
});
